import PropTypes from "prop-types";
import Flex from "sholdi-primitives/atoms/Flex";
import ArrowLeft from "sholdi-icons/ArrowLeft";

const PreviousCategory = ({ category, onPrevious }) => (
  <Flex
    as="a"
    onClick={() => onPrevious()}
    className="w-full flex items-center justify-between gap-2 px-3 py-4 text-primary-main border-b border-gray-200 cursor-pointer"
  >
    <ArrowLeft className="size-6" />
    <div>{category.title}</div>
  </Flex>
);

PreviousCategory.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default PreviousCategory;
