import { memo, useMemo, useState } from "react";
import PropTypes from "prop-types";

import Logo from "sholdi-primitives/atoms/Logo";
import IconButton from "sholdi-primitives/atoms/IconButton";
import Close from "sholdi-icons/Close";

import MenuList from "./MenuList";
import UserCtas from "./UserCtas";

const MobileMenu = ({ onClose, menuItems = [], isLoggedIn }) => {
  const [activeCategory, setActiveCategory] = useState(false);
  const [previous, setPrevious] = useState([]);

  const onChoseParent = (item) => {
    const previousArr = [...previous, item];
    setPrevious(previousArr);
    setActiveCategory(item);
  };

  const onPrevious = () => {
    const previousArr = [...previous];
    previousArr.pop();
    setPrevious(previousArr);
    setActiveCategory(previousArr.pop());
  };
  const menuLinks = useMemo(
    () =>
      !isLoggedIn
        ? menuItems
        : [
            { link: "/profile", title: "Profile", id: "profil", menuItems: [] },
            {
              link: "/wishlists",
              title: "Wishlists",
              id: "wishlist",
              menuItems: [],
            },
            ...(menuItems.length ? menuItems : []),
          ],
    [menuItems, isLoggedIn],
  );
  return (
    <div className="block lg:hidden absolute w-full h-screen left-0 top-0 z-[99] bg-white">
      <div className="flex justify-between items-center py-4 px-3 border-b border-gray-200">
        <Logo className="w-[135px] h-10" color="black" />
        <IconButton onClick={() => onClose()}>
          <Close className="size-8 stroke-primary-main" />
        </IconButton>
      </div>
      <MenuList
        onClose={onClose}
        menuItems={!activeCategory ? menuLinks : activeCategory?.menuItems}
        category={activeCategory}
        onChoseParent={onChoseParent}
        onPrevious={onPrevious}
      />
      <UserCtas />
    </div>
  );
};

MobileMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.shape({}),
};

export default memo(MobileMenu);
