import { useRef } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { twMerge } from 'tailwind-merge'

import Container from 'sholdi-ui/molecules/Container'
import MainNavbar from 'sholdi-ui/organisms/MainNavbar'

import useSettings from 'sholdi-hooks/useSettings'
import I18n from 'sholdi-providers/I18n'
import useUserAgent from 'sholdi-hooks/useUserAgent'

import AddToHomeScreen from 'components/notifications/AddToHomeScreen'

const Footer = dynamic(() => import('sholdi-ui/organisms/Footer'), {
  loading: () => <p>Loading...</p>,
})
const SholdiClubBanner = dynamic(() => import('sholdi-ui/organisms/SholdiClubBanner'), {
  loading: () => <p>Loading...</p>,
})

import publicBS from 'sholdi-locales/public-bs.json'

const MainLayout = ({
  children,
  fluid,
  subheader,
  subFooter,
  childrenProps,
  className,
  // ...props
}) => {
  const footerRef = useRef()
  const { isMobile, isIOS } = useUserAgent()
  const settings = useSettings()

  const { menu, footerMenus } = settings

  // TODO load custom mobile menu instead of footer menu for the main mobile menu
  return (
    <div className="w-full bg-white text-[rgb(34,34,34)]">
      <I18n locale="BS" lngDict={publicBS}>
        <MainNavbar menu={menu} mobileMenuItems={menu?.menuItems || []} />
        {subheader && subheader}
        <Container fluid={fluid} className={className}>
          <div className="flex min-h-screen w-full pb-8">
            <div
              className={twMerge(
                'w-full flex flex-1 justify-center',
                childrenProps?.className,
              )}
              {...childrenProps}
            >
              {children}
            </div>
          </div>
          <SholdiClubBanner className="mb-10" />
        </Container>
        {subFooter && subFooter}
        <Footer menus={footerMenus} ref={footerRef} />
        {isMobile && isIOS && <AddToHomeScreen />}
      </I18n>
    </div>
  )
}

MainLayout.displayName = 'MainLayout'

MainLayout.propTypes = {
  children: PropTypes.node,
  fluid: PropTypes.bool,
  myAccountMenuContent: PropTypes.shape({}),
  subheader: PropTypes.node,
  subFooter: PropTypes.node,
  childrenProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default MainLayout
