import PropTypes from "prop-types";
import Text from "sholdi-primitives/atoms/Text";
import IconButton from "sholdi-primitives/atoms/IconButton";
import ArrowRight from "sholdi-icons/ArrowRight";
import Image from "sholdi-primitives/atoms/Image";

const MegaMenuBanner = ({ position }) => (
  //TODO load dynamically banner
  <div className="w-[32%] relative">
    <div
      style={{
        background:
          "linear-gradient(180deg, #FFFFFF 0.75%, rgba(255, 255, 255, 0) 48.48%), linear-gradient(360deg, rgba(0, 0, 0, 0.64) 13.2%, rgba(0, 0, 0, 0) 45.39%) ",
      }}
      className="relative h-full p-6 flex flex-col justify-between z-10"
    >
      <div className="text-4xl font-heading font-semibold pb-4">
        Iphone 14 PRO {position}
      </div>
      <Text>
        iPhone 13 Pro Max. Najveće poboljšanje Pro sistema kamere ikad. Super
        Retina XDR s tehnologijom ProMotion za brže i responzivnije iskustvo.
      </Text>

      <div className="flex justify-between items-center mt-auto">
        <div>
          <div className="text-white font-semibold font-heading text-sm mb-2">
            Sholdi donosi
          </div>

          <div className="text-white font-semibold font-heading text-2xl mb-2">
            Najbolje ponude
          </div>
          <div className="text-white font-heading text-sm">Popusti do 50%</div>
        </div>
        <IconButton className="size-16 text-white rounded-full bg-primary-main">
          <ArrowRight className="size-16" />
        </IconButton>
      </div>
    </div>

    <Image
      layout="fill"
      alt={`banner-${position}`}
      src="https://images.unsplash.com/photo-1591337676887-a217a6970a8a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80"
      className="absolute top-0 left-0 size-[200px]"
    ></Image>
  </div>
);
MegaMenuBanner.displayName = "MegaMenuBanner";
MegaMenuBanner.propTypes = {
  position: PropTypes.string,
};

export default MegaMenuBanner;
