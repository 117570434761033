import SVG from './SVG'

const LogoCoinIcon = props => (
  <SVG
    viewBox="0 0 90 90"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.16 84.184c6.336 2.992 14.784 4.576 22.176 4.576 19.712 0 35.552-10.56 35.552-27.808 0-12.672-6.336-20.768-22.88-24.64-5.808-1.408-9.504-4.224-9.504-8.976 0-5.28 5.104-8.272 12.144-8.272 6.336 0 11.968 1.408 17.248 4.224L56.304 4.28C51.376 2.168 44.688.584 35.008.584 17.76.584 1.92 10.088 1.92 28.568c0 13.2 6.512 20.944 21.472 24.816 7.216 1.936 11.088 4.4 11.088 8.976 0 4.752-4.576 7.744-12.144 7.744-6.336 0-13.024-1.056-20.416-4.4L.16 84.184Z"
      fill="#fff"
    />
    <path
      d="M67.554 79.76c0 6.619-4.725 11.984-10.554 11.984-5.829 0-10.554-5.365-10.554-11.984 0-6.618 4.725-11.983 10.554-11.983 5.829 0 10.554 5.365 10.554 11.983Z"
      fill="#FFBF34"
    />
  </SVG>
)

export default LogoCoinIcon
