import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";
import { memo } from "react";

const Bell = ({ className, ...props }) => (
  <SVG aria-label="Bell" className={twMerge("w-5 h-5.5", className)} {...props}>
    <path
      d="M11.73 20C11.5542 20.3031 11.3018 20.5547 10.9982 20.7295C10.6946 20.9044 10.3504 20.9965 10 20.9965C9.64962 20.9965 9.30539 20.9044 9.00177 20.7295C8.69816 20.5547 8.44581 20.3031 8.27 20M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Bell.propTypes = {
  className: PropTypes.string,
};

export default memo(Bell);
