import { useState, useEffect, useMemo, memo } from "react";
import { useSession } from "next-auth/react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { twMerge } from "tailwind-merge";

import Image from "sholdi-primitives/atoms/Image";
import Link from "sholdi-primitives/atoms/Link";
import IconButton from "sholdi-primitives/atoms/IconButton";
import Logo from "sholdi-primitives/atoms/Logo";
import LogoCoinIcon from "sholdi-primitives/atoms/LogoCoinIcon";
import Container from "../../molecules/Container";
import MobileMenu from "../MainNavbar/MobileMenu";
import MegaMenu from "../MegaMenu";
import useDisableDocumentScroll from "sholdi-hooks/useDisableDocumentScroll";

import { useGetUserNavBar } from "sholdi-graphql/operations/users/useGetUserNavBar";
import { push } from "sholdi-shared/lib/matomo";

import Heart from "sholdi-icons/Heart";
import User from "sholdi-icons/User";
import Burger from "sholdi-icons/Burger";
import Bell from "sholdi-icons/Bell";
import ShoppingCart from "sholdi-icons/ShoppingCart";
import Search from "./Search";

const UserCtas = dynamic(() => import("./UserCtas"));

const AsideShoppingCart = memo(
  dynamic(() => import("../../organisms/AsideShoppingCart"), {
    loading: () => <ShoppingCart className="size-6" />,
  }),
);

const Notifications = memo(
  dynamic(() => import("./Notifications"), {
    loading: () => <Bell className="size-6 hidden lg:flex" />,
  }),
);

const MainNavbar = ({ menu, mobileMenuItems, className, ...props }) => {
  const [mobileMenu, isMobileMenuActive] = useState(false);
  const { data: session } = useSession();
  const hasSession = !!session?.user.id;

  useEffect(() => {
    if (session) {
      push(["setUserId", session?.user.email]);
    }
  }, [session]);

  const { data } = useGetUserNavBar({
    variables: {
      id: session?.user.id,
    },
    pause: !session,
  });

  const { user } = data || {};

  useDisableDocumentScroll(mobileMenu);

  const renderUserActions = useMemo(() => {
    if (hasSession) {
      return (
        <>
          <Link
            href="/wishlists"
            className="text-white hidden lg:block p-0 w-6 h-6"
          >
            <Heart className="w-6 h-6" />
          </Link>
          <IconButton
            as={Link}
            href="/profile"
            className={twMerge(
              "hidden lg:block p-0 size-6 rounded-full",
              "bg-white text-primary-main",
            )}
          >
            {user?.avatar ? (
              <Image
                alt="User avatar"
                size="avatarXs"
                src={user.avatar}
                shape="circle"
              />
            ) : (
              <User />
            )}
          </IconButton>
        </>
      );
    }
    return <UserCtas className="hidden lg:block" />;
  }, [hasSession, user]);

  return (
    <div className="relative pb-18 md:pb-20 lg:pb-30">
      <div className={twMerge("w-full fixed z-[99]", className)} {...props}>
        <div className="w-full relative bg-primary-main">
          <Container className="overflow-x-visible px-0">
            <div className="flex justify-between items-center gap-3 p-4 pr-5 text-white">
              <button
                onClick={() => isMobileMenuActive(true)}
                className="block lg:hidden text-white"
              >
                <Burger className="w-8 h-6" />
              </button>
              <Link
                href="/"
                aria-label="logo"
                className="relative lg:w-32 xl:w-36 text-white"
              >
                <LogoCoinIcon className="w-6 h-auto block lg:hidden" />
                <Logo className="w-full h-9 hidden lg:block" />
              </Link>
              <Search className="max-w-3xl" />
              <div className="flex justify-between w-max min-w-unset gap-3">
                {session && <Notifications userId={session?.user?.id} />}
                {renderUserActions}
                <AsideShoppingCart className="w-full md:w-[390px]" />
              </div>
            </div>
          </Container>
        </div>
        {menu && <MegaMenu menuItems={menu?.menuItems ?? []} />}
        {mobileMenu && (
          <MobileMenu
            isLoggedIn={session?.user}
            onClose={() => isMobileMenuActive(false)}
            mobileMenu={mobileMenu}
            menuItems={mobileMenuItems}
          />
        )}
      </div>
    </div>
  );
};

MainNavbar.displayName = "MainNavbar";

MainNavbar.propTypes = {
  myAccountMenuContent: PropTypes.shape({}),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  megaMenuItems: PropTypes.arrayOf(PropTypes.shape({})),
  menu: PropTypes.shape({
    menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mobileMenuItems: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

export default MainNavbar;
