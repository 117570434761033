import { memo } from "react"
import { useSession } from "next-auth/react";
import dynamic from "next/dynamic";
import useTranslation from "sholdi-hooks/useTranslation";

const User = dynamic(() => import("sholdi-icons/User"));
const Heart = dynamic(() => import("sholdi-icons/Heart"));

import Item from "./Item";

function UserCtas() {
  const { data: session } = useSession();
  const { t } = useTranslation();

  return (
    <div className="fixed bottom-0 w-full">
      {session ? (
        <>
          <Item href="/profile" title={t("settings.profile")} icon={User} />
          <Item href="/wishlists" title={t("users.wishlists")} icon={Heart} />
        </>
      ) : (
        <>
          <Item href="/profile" title={t("users.logIn")} />
          <Item href="/register" title={t("register")} />
        </>
      )}
    </div>
  );
}

export default memo(UserCtas);
