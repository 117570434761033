import { useState } from "react";
import PropTypes from "prop-types";

import useDelayedHover from "sholdi-hooks/useDelayedHover";

import ClickOutside from "../../lib/ClickOutside";
import Link from "sholdi-primitives/atoms/Link";
import Container from "../../molecules/Container";
import MenuItem from "./MenuItem";
import MegaMenuBanner from "./MegaMenuBanner";

const MegaMenu = ({ menuItems }) => {
  const [active, setActive] = useState(false);
  const onHover = (e) => {
    setActive(e);
  };

  const itemGroup = active?.menuItems || [];

  const { handleMouseEnter, handleMouseLeave } = useDelayedHover(
    onHover,
    active ? 20 : 300,
  );

  return (
    <>
      <div className="bg-primary-dark py-4 hidden lg:block">
        <Container className="p-0">
          <div className="flex flex-wrap">
            {menuItems.map((item) => (
              <MenuItem
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                item={item}
                key={item.id}
              />
            ))}
          </div>
        </Container>
      </div>
      {!!active && (
        <ClickOutside
          active={!!active}
          onClick={() => {
            setActive(false);
          }}
        >
          <Container className="bg-white shadow-md mx-auto">
            <div className="flex justify-between">
              <div className="w-2/3 box-border p-7 columns-3">
                <Link
                  href={active.link}
                  style={{ columnSpan: "all" }}
                  className="text-primary-main font-medium block mb-6"
                >
                  {active.title}
                </Link>
                {itemGroup.map(
                  (item, i) => (
                    <div
                      key={`${item.id}-${i}`}
                      style={{ pageBreakInside: "avoid" }}
                      className="mb-9"
                    >
                      <Link
                        href={item.link}
                        className="block font-medium text-black mb-4"
                      >
                        {item.title}
                      </Link>
                      {item.menuItems.map((childItem, i) => (
                        <Link
                          key={`${childItem.id}-${i}`}
                          href={childItem.link}
                          className="block text-black mb-2"
                        >
                          {childItem.title}
                        </Link>
                      ))}
                    </div>
                  ),
                  [],
                )}
              </div>
              <MegaMenuBanner position={active.id} />
            </div>
          </Container>
        </ClickOutside>
      )}
    </>
  );
};

MegaMenu.displayName = "MegaMenu";
MegaMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      menuItems: PropTypes.arrayOf(PropTypes.shape({})),
      id: PropTypes.string,
    }),
  ),
};

export default MegaMenu;
